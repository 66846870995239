import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import TabContents from "../../components/tabContents";


const Southwestern = () => {
  return (
    <Layout>
      <SEO title="Work" />
      <div>
        <TabContents selectedTab={0} />
      </div>
    </Layout>
  )
};

export default Southwestern;